import React from "react";
import { graphql } from "gatsby";
import { Grid, Image } from "semantic-ui-react";
import Helmet from "react-helmet";
import Markdown from "markdown-to-jsx";
import {
  PageLayout,
  CardNavigation,
  HeroElement,
  About,
} from "@fishrmn/fishrmn-components";

import Layout from "../components/Layout";

export default class extends React.PureComponent {
  render() {
    const businessData = this.props.data.allFisherman.edges[0].node;
    const imageData = this.props.data.allFishermanImages.edges[0].node;
    const data = { ...businessData, ...imageData };

    return (
      <Layout>
        <Helmet>
          <title>{data.businessName} | Our Story</title>
          <meta name="description" content="" />
        </Helmet>
        <PageLayout
          hero={
            <HeroElement
              header={<h1>Our Story</h1>}
              tagline={``}
              images={[
                "https://fisherman.gumlet.io/public/eggandbird/Our_Story-min.jpg"
              ]}
              showMap={false}
              ctas={[]}
              ctaInverted={true}
              ctaColor={"white"}
              containerColor={"primary"}
              textColor={"white"}
              showMultiple={true}
              overlay={true}
              fullWidth={true}
              parallax={true}
              containerAs={"none"}
              height={500}
              gutter={false}
            />
          }
        >
          <Grid
            stackable
            className="component-section-container"
            verticalAlign={"middle"}
            textAlign={"center"}
          >
            <Grid.Column width={12}>
              <About
                content={
                  <>
                    <p>
                    In 2021, Egg N Bird debuted in Maywood, CA, created by Claire Chang, a rising star in the culinary world known for her innovative Korean-infused dishes. Claire's journey began in her childhood, was honed at Le Cordon Bleu in Paris, and further refined at Jean Georges, a Michelin-starred restaurant in New York. A UCLA graduate, she excels in blending secret family recipes with bold, international influences.
                    </p>
                    <p>
                    Her menu, centered on simple ingredients like eggs and chicken, showcases the rich flavors of Korea enhanced by her global travels. As Egg N Bird grows, Claire remains dedicated to her heritage and aims to spread joy and hope through her food.
                    </p>
                  </>
                }
                header={"Our Story"}
                centerContent={true}
                headerAs={"h1"}
                withContainer={true}
              />
            </Grid.Column>
          </Grid>
        </PageLayout>
      </Layout>
    );
  }
}

export const query = graphql`
  query {
    allFisherman {
      edges {
        node {
          businessName
          aboutMarkdown
        }
      }
    }
    allFishermanImages {
      edges {
        node {
          galleryImages {
            url
          }
          heroImages {
            url
          }
        }
      }
    }
  }
`;
